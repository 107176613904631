<template>
  <div class="after-before-block__content">
    <div
      ref="desc"
      v-if="slide.description"
      class="after-before-block__description"
      >
        {{ displayedDescription }}
    </div>
    <button @click="toggleHide" v-if="!hideDesc && slide.description.length > 220" class="button button--secondary after-before-block__btn button--size-s">Читать полностью</button>
  </div>
</template>

<script setup lang="ts">
import type { ImageItem, ImageSEO } from '~/types/pages';
import { ref, computed } from '#imports';
import './styles.scss';
interface Props {
  slide: {
    image1: ImageItem;
    image2: ImageItem;
    image1Seo: ImageSEO;
    image2Seo: ImageSEO;
    description: string;
  }[];
}

const props = defineProps<Props>();

const hideDesc = ref(false);

const toggleHide = () => {
  hideDesc.value = !hideDesc.value
}

const displayedDescription = computed(() => {
    if (props.slide.description && !hideDesc.value && props.slide.description.length > 220) {
        return props.slide.description.substring(0, 220) + '...'
    }
  return props.slide.description;
})


</script>
